@keyframes animatesidelinks{
    0%{
        visibility: hidden;

    }
    100%{
        visibility: visible;

    }
}

.animate_s_links{
    animation: animatesidelinks 4s linear 1 forwards;
}