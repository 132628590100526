.usercontent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.userdiv1{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}
.userdiv1 > button:last-of-type{
    margin-left:auto;
}
.usersvg2{
    height: 20px;
    width: 20px;
}
.usersvg3{
    height: 25px;
    width: 25px;
    color: var(--primary-color);
    cursor: pointer;
}
.userdiv1 > button{
    height: 40px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    outline: none;
    border: none;
    color: var(--white-color);
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--primary-color);
}

/*
.userdiv2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px;
    align-items: center;
}
 .userdiv2 > div{
    display: flex;
    height: 100px;
    width: 100%;
    background-color: var(--white-color);
    padding: 10px 20px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--light-shadow);
}
.userdiv2 > div > div{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.userdiv2 > div > div > span:first-of-type{
    color: var(--light-grey);
}
.userdiv2 > div > div > span:nth-of-type(2){
    font-weight: 600;
    font-size: 17px;
    color: var(--text-light-color);
}
.userdiv2 > div > div > div:first-of-type{
    font-weight: 600;
    font-size: 17px;
    color: var(--text-light-color);
    display: flex;
    gap: 10px;
    align-items: center;
} */

@media screen and (width <= 800px) {
    .userdiv1{
        flex-wrap: wrap;
        gap:10px;
    }
    .userdiv1 > button:last-of-type{
        margin-left: 0;
    }
}