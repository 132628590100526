:root{
    --red-color:#C10A28;
    --primary-color:#002087;
    --light-blue:#8AB4F8;
    --text-color:#222;
    --text-light-color:#222;
    --text-dark-color:#fefefe;
    --white-color:#fff;
    --white-color-2:#00061a;
    --black-color:#000;
    --modal-shadow:1px 1px 10px 2px rgba(0,0,0,0.1);
    --high-light-blue:rgba(180, 199, 255, 0.1);
    --primary-light-color:#002087;
    --secondary-light-back:#fafafa;
    --primary-light-back:#fff;
    --primary-dark-color:#ccd8ff;
    --primary-dark-back:#00061a;
    --secondary-dark-back:#000c33;
    --border-color:#ddd;
    --transparent:transparent;
    --notification-success-back:#e6fff2;
    --notification-success-color:#194d19;
    --notification-error-back:#ffe6e6;
    --notification-error-color:#990000;
    --light-shadow:2px 2px 8px 2px rgba(0,0,0,0.15);
    --dark-shadow:2px 2px 8px 2px rgba(255,255,255,0.4);
    --white-grey-color: #F2F3F5;
    --light-grey: #949494;
}