.groupcontent{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
}
.groupdiv1{
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
}
.groupdiv1 > div{
    display: flex;
    gap: 20px;
    width: 80%;
    align-items: center;
}
.groupdiv1 > div > div:first-of-type{
    width: 60%;
    display: flex;
    align-items: center;
    background-color: var(--white-color);
    height: 40px;
    border: 2px  solid var(--primary-color);
    border-radius: 10px;
    padding-left: 5px;
}
.groupsvg1{
    width: 10%;
    height: 20px;
}
.groupdiv1 > div > div:first-of-type > input{
    width: 70%;
    padding-left: 3px;
    border: none;
    outline: none;
    height: 100%;
}
.groupdiv1 > button:last-of-type{
    margin-left:auto;
}
.groupdiv1 > div > div:first-of-type > button{
    border-radius: 10px 5px 5px 10px;
    outline: none;
    height: 100%;
    border: none;
    width: 20%;
    font-weight: 700;
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--white-color);
}
.groupdiv1 > div > div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    position: relative;
}
.groupdiv1 > div > div:nth-of-type(2) > button:first-of-type{
    width: 100px;
    height: 40px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    outline: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--primary-dark-color);
}
.groupdiv1 > div > div:nth-of-type(2) > div{
    display: none;
    flex-direction: column;
    position: absolute;
    margin-top: 45px;
    height: fit-content;
    padding: 5px;
    background-color: var(--white-color);
    box-shadow: var(--light-shadow);
    width: 200px;
    border-radius: 5px;
}
.groupdiv1 > div > div:nth-of-type(2) > div > button{
    outline: none;
    background-color: var(--white-color);
    height: 35px;
    width: 100%;
    border: none;
    text-align: left;
    color: var(--primary-color);
    cursor: pointer;
    
}
.groupdiv1 > div > div:nth-of-type(2) > div > button:hover{
    background-color: var(--primary-color);
    color: var(--white-color);
}
.groupsvg2{
    height: 20px;
    width: 20px;
}
.groupsvg3{
    height: 25px;
    width: 25px;
    color: var(--primary-color);
    cursor: pointer;
}
.groupdiv1 > button{
    height: 40px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    outline: none;
    border: none;
    color: var(--white-color);
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--primary-color);
}
/* 
.groupdiv2{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 0px;
    align-items: center;
}
.groupdiv2 > div{
    display: flex;
    height: 100px;
    width: 100%;
    background-color: var(--white-color);
    padding: 10px 20px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--light-shadow);
}
.groupdiv2 > div > div{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.groupdiv2 > div > div > span:first-of-type{
    color: var(--light-grey);
}
.groupdiv2 > div > div > span:nth-of-type(2){
    font-weight: 600;
    font-size: 17px;
    color: var(--text-light-color);
}
.groupdiv2 > div > div > div:first-of-type{
    font-weight: 600;
    font-size: 17px;
    color: var(--text-light-color);
    display: flex;
    gap: 10px;
    align-items: center;
} */

@media screen and (width <= 800px) {
    .groupdiv1{
        flex-wrap: wrap;
        gap:10px;
    }
    .groupdiv1 > button:last-of-type{
        margin-left: 0;
    }
}